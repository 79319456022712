import api from '@/api/tongji';

export default {
  data() {
    return {
      provinceHospitalList: [],
    }
  },
  mounted() {
    this.getProvince_hospital();
  },
  methods: {
    // 根据省份获取医院列表
    getProvince_hospital() {
      api.province_hospital({}).then((res) => {
        if (res.code === 200) {
          this.provinceHospitalList = res.data.data;
        }
      });
    },
    // 省份改变 页面内有自己的方法时会被覆盖
    changeProvince() {
      this.hospitalList = this.provinceHospitalList.find(e=> this.province_id==e.province_id).hospital_list.map(e=> {
        return {
          value: e.hospital_id,
          label: e.hospital_full_name,
        }
      })
      this.hospital_id='';
    },
  }

}